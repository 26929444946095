import React, { useEffect, lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Dashboard from './Dashboard';

import AgentInterface from './AgentInterface';
import { setToken } from './tokenHelper';

// Helper function for lazy loading without preloading

const AgentNavbar = lazy(() => import('./AgentNavbar'));
const AgentBooking = lazy(() => import('./AgentBooking'));
const AgentBooknow = lazy(() => import('./AgentBooknow'));
const AddSub = lazy(() => import('./AddSub'));

const Cal = lazy(() => import('./Cal'));
const Footer = lazy(() => import('./Footer'));
const PaymentInterface = lazy(() => import('./PaymentInterface'));
const MyProfile = lazy(() => import('./MyProfile'));
const MyForm = lazy(() => import('./MyForm'));
const ForgotPasswordAgent = lazy(() => import('./ForgotPasswordAgent'));
const Pincode = lazy(() => import('./Pincode'));
const DistanceCalculator = lazy(() => import('./DistanceCalculator'));
const Location = lazy(() => import('./Location'));
const AgentMyProfile = lazy(() => import('./AgentMyProfile'));
const Bookinganimation = lazy(() => import('./Bookinganimation'));
const AgentForm = lazy(() => import('./AgentForm'));

// Loading animation component
const Loading = () => (
  <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
    <div className="lds-circle"><div></div></div>
    <style jsx>{`
      .lds-circle {
        display: inline-block;
        transform: translateZ(1px);
      }
      .lds-circle > div {
        display: inline-block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        background: #000;
        animation: lds-circle 1.2s linear infinite;
      }
      @keyframes lds-circle {
        0%, 100% {
          animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
        }
        0% {
          transform: rotateY(0deg);
        }
        50% {
          transform: rotateY(1800deg);
          animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
        }
        100% {
          transform: rotateY(3600deg);
        }
      }
    `}</style>
  </div>
);

export default function App() {
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setToken(token);
    }
  }, []);

  return (
    <div>
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path='/' element={<Dashboard />} />
            <Route path='Dashboard' element={<Dashboard />} />
            <Route path='AgentInterface' element={<AgentInterface />} />
            <Route path='AgentNavbar' element={<AgentNavbar />} />
            <Route path='AgentBooking' element={<AgentBooking />} />
            <Route path='AgentBooknow' element={<AgentBooknow />} />
            <Route path='AddSub' element={<AddSub />} />
            <Route path='Cal' element={<Cal />} />
            <Route path='Footer' element={<Footer />} />
            <Route path='PaymentInterface' element={<PaymentInterface />} />
            <Route path='MyProfile' element={<MyProfile />} />
            <Route path='MyForm' element={<MyForm />} />
            <Route path='ForgotPasswordAgent' element={<ForgotPasswordAgent />} />
            <Route path='Pincode' element={<Pincode />} />
            <Route path='DistanceCalculator' element={<DistanceCalculator />} />
            <Route path='Location' element={<Location />} />
            <Route path='AgentMyProfile' element={<AgentMyProfile />} />
            <Route path='Bookinganimation' element={<Bookinganimation />} />
            <Route path='AgentForm' element={<AgentForm />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}
