import React, { useState, useEffect } from 'react';
import './AgentInterface.css';
import axios from 'axios';
import { Modal, Carousel } from 'react-bootstrap';

// import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import AgentNavbar from './AgentNavbar';
import Alert from '@mui/material/Alert';
import { Button } from '@mui/material';
import Footer from "./Footer";

function AgentInterface() {
  const [trucks, setTrucks] = useState([]);
  const [selectedTruckImage, setSelectedTruckImage] = useState(null);
  const navigate = useNavigate();
  const token = localStorage.getItem('agenttoken')
const crn = sessionStorage.getItem('agentCRN')
  const headers = {
      Authorization: `Bearer ${token}`,
    };
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(`https://mynode.trucksbooking.in/Trucks?crn=${crn}`, { headers });
          console.log('Received truck data from backend:', response.data);
  
          const currentDateTime = new Date();
          const filteredTrucks = response.data.filter((truck) => {
            const truckDateTime = new Date(truck.date + ' ' + truck.time);
            return truckDateTime > currentDateTime;
          });
  
          setTrucks(filteredTrucks);
          console.log(filteredTrucks);
        } catch (error) {
          console.error('Error fetching truck data:', error);
        }
      };
  
      fetchData();
    }, []);
  const location = useLocation();
  const { agentType, name,agentId, phonenumber } = location.state || {}; // Retrieve agentType from state
  useEffect(()=>{
    if(!token){
  navigate('/Dashboard')
    }
  })
  // Now you can use the agentType as needed in this component
  console.log('Agent Type:', agentType);
  console.log('Agent name:', name);
  console.log('Agent name:', phonenumber);
  console.log('Agent name:', crn);
  console.log('Agent name:', agentId);

  // useEffect(() => {
  //   // Check if agent credentials exist in session storage
  //   const agentName = sessionStorage.getItem("agentName");

  //   if (!name) {
  //     // Redirect to the login page if credentials are missing
  //     navigate('/Dashboard');
  //   }
  // }, [navigate]);
  const handleBookNowClick = (truckData) => {
    const truckDataToPass = {
      date: truckData.date,
      from: truckData.from,
      time: truckData.time,
      to: truckData.to,
      truckMaxWeight: truckData.truckMaxWeight,
      truckNumber: truckData.truckNumber,
      truckWheels: truckData.truckWheels,
      loadingSublocations: truckData.loadingSublocations,
      unloadingSublocations: truckData.unloadingSublocations,
      ton: truckData.ton,
      kilometer: truckData.kilometer,
      pricePerTonKm: truckData.pricePerTonKm,
      totalkilometers: truckData.totalkilometers,
      truckPostingID:truckData.truckPostingID
    };

    const stateToPass = {
      truckData: truckDataToPass,
      agentType: agentType,
      name: name,
      agentId: agentId,
      phonenumber: phonenumber,
      crn: crn,
    };

    console.log(stateToPass);
const main = 'hello'
localStorage.setItem('hello',main)
    navigate('/AgentBooknow', { state: stateToPass });
  };
  function formatTruckNumber(truckNumber) {
    if (truckNumber && truckNumber.length >= 6) {
      const hiddenPart = '*'.repeat(6); // Create a string of 6 asterisks
      const visiblePart = truckNumber.slice(6); // Get the remaining characters
      return hiddenPart + visiblePart; // Combine the hidden and visible parts
    }
    return truckNumber; // Return the original truck number if it's shorter than 6 characters
  }

  

  const [AgentshowSuccessMessage, AgentsetShowSuccessMessage] = useState(false);


  useEffect(() => {
    // Check if the success message should be displayed
    const shouldDisplayMessage = sessionStorage.getItem('AgentSuccessMessage');
  
    if (shouldDisplayMessage) {
      // Display the success message
      AgentsetShowSuccessMessage(true);
      // Remove the flag from sessionStorage
      sessionStorage.removeItem('AgentSuccessMessage');
      // Automatically hide the message after 5 seconds
      setTimeout(() => {
        AgentsetShowSuccessMessage(false);
      }, 5000);
    }
  }, []);
  const handleTruckImageClick = (truck) => {
    setSelectedTruckImage(truck);
  };

  const closeModal = () => {
    setSelectedTruckImage(null);
  };

  console.log(selectedTruckImage);

  return (
    <div className='agentinterface-bg'>
  
      <AgentNavbar agentName={name} agentCRN={crn} />
    
      {AgentshowSuccessMessage && (
        <div className="success-message mt-4 p-3" >
              <Alert severity="success" variant='filled'>
              <strong> Login Successful !</strong>

             </Alert>
        </div>
      )}


        <h1 className='txt mt-2 mb-0' style={{fontFamily:'Segoe UI',textShadow:'1px 2px 2px gray',textAlign:"center"}}>Avaliable Trucks</h1>
      <div className="container hii-10" style={{minHeight:'100vh'}}>
        <div className="content-10">
          {trucks.map((truck) => (
            <div key={truck.id}>
              <table className="table table-bordered border-5 table-hover   table-primary table-striped">
                <tbody>
                   <tr >
                  <td className="text-center align-middle d-md-none" colSpan='4' > 
                      <img
                        src={truck.rightsideUrl}
                        alt="Truck"
                        className="vehicle-image-10"
                      />
                    </td>
                  </tr> 
                <tr > 
                    <th className="text-center align-middle col-12 col-md-3 d-none d-md-table-cell"  rowSpan="4"> {/* Adjust col-2 to your preference */}
                      <img
                        src={truck.rightsideUrl}
                        alt="Truck"
                        className="vehicle-image-10"
                        style={{height:'180px'}}
                        onClick={() => handleTruckImageClick(truck)}
                      />
                    </th >
                    <td className="col-3" > {/* Adjust col-4 to your preference */}
                      Registration Number: <b>{formatTruckNumber(truck.truckNumber)}</b>
                    </td>
                    <td className="col-3" > {/* Adjust col-3 to your preference */}
                      Truck Wheels: <b> {truck.truckWheels}</b>
                    </td>
                  </tr>

                  <tr >
                    <td >Available Date:  <b>{truck.date.split('-').reverse().join('-')}</b></td>
                    <td>Time: <b> {truck.time} </b></td>
                  </tr>
                  <tr >
                    <td >From Location: <b> {truck.from} </b></td>
                    <td >To Location:  <b>{truck.to} </b></td>
                  </tr>
                  <tr >
                    <td >Max Weight: <b>{truck.truckMaxWeight} </b></td>
                    <td >
                      <Button type="button" variant='contained'
                        onClick={() => handleBookNowClick(truck)}
                        className="  ps-4 pe-4 book-button">
                        Book Now
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              <hr />
              <br />
            </div>
          ))}
        </div>
      </div>
      <Modal show={selectedTruckImage !== null} onHide={closeModal} size="lg"  id='image-carousel-in-agentinterface'>
        <Modal.Header closeButton>
          <Modal.Title>Truck Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {selectedTruckImage && (
          <Carousel className=''>
                {/* {console.log('Right Side Image URL:', selectedTruckImage.rightsideUrl)} */}
                <Carousel.Item > 
                <img className="d-block w-100 " src={selectedTruckImage.rightsideUrl} alt="Truck" />
                </Carousel.Item>
                <Carousel.Item >
                  {/* {console.log('Inside Carousel.Item:', selectedTruckImage.leftsideUrl)} */}
                  <img className="d-block w-100" src={selectedTruckImage.leftsideUrl} alt="Truck" />
                </Carousel.Item>
                <Carousel.Item >
                  <img className="d-block w-100" src={selectedTruckImage.leftsideUrl} alt="Truck" />
                </Carousel.Item>
          </Carousel>
           )}
 
          
        </Modal.Body>
      </Modal>
      <Footer/>

    </div>
  );
}

export default AgentInterface;
