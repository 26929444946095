import React, { useState,useEffect } from 'react';
import { useNavigate,Link } from 'react-router-dom';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import './Dashboard.css';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';  
import { motion } from 'framer-motion';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent'
import {  CardActionArea } from '@mui/material';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SmsIcon from '@mui/icons-material/Sms';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EditIcon from '@mui/icons-material/Edit';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import {IconButton} from '@mui/material';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Footer from "./Footer";

import img from './images/grctob logo.png'
import imgage from './images/img.jpg'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : 'white',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };
  

function Dashboard() {
  const navigate=useNavigate();
  const [alertmsg,setalertmsg]=useState(false);

  const [popupVisible1, setPopupVisible1] = useState(false);
  const [chatPopupVisible, setChatPopupVisible] = useState(false);
  const [popupVisible2, setPopupVisible2] = useState(false);
  const [popupVisible3, setPopupVisible3] = useState(false);
  const [phonenumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [fromPincode, setFromPincode] = useState('');
  const [toPincode, setToPincode] = useState('');
  const [message, setMessage] = useState('');
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [expandedAccordion, setExpandedAccordion] = useState('accordion1');
  const [showPassword, setShowPassword] = useState(false);
  const [results, setResults] = useState([]);

  const navigatetodashboard =()=> {
    navigate('/faq')
  }

  const handleChange = (accordion) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? accordion : null);
  };
  const calculateDistance = (e) => {
    e.preventDefault() 
    const pincodeRegex = /^\d{6}$/;
    if (!pincodeRegex.test(fromPincode) || !pincodeRegex.test(toPincode)) {
      setError("Please enter valid 6-digit pin codes.");
      return;
    }
    const apiKey = '6eab0fa444c0b3ea0ddce568eceef557'; // Replace with your actual MapPLS API key
    const modes = ['driving', 'trucking', 'walking', 'biking'];
    // Create an array to hold promises for fetching data for each mode
    const promises = modes.map(mode => {
      const apiUrl = `https://apis.mappls.com/advancedmaps/v1/${apiKey}/distance_matrix/${mode}/${fromPincode}%3B${toPincode}?rtype=0&region=ind`;
      return fetch(apiUrl)
        .then(response => {
          if (!response.ok) { 
  
            throw new Error(`HTTP error! Status: ${response.status}`);
             
          }
          return response.json();
        })
        .then(data => {
          if (data.results && data.results.distances && data.results.durations) {
            const distance = data.results.distances[0][1] / 1000;
            const duration = data.results.durations[0][1] / 60;
            return { mode, distance, duration };
          } else {
            throw new Error('Distance or duration not found in the response.');
          }
        })
        .catch(error => {
          console.error(Error `fetching data for ${mode}:`, error);
          return { mode, distance: null, duration: null, error: Error `fetching distance for ${mode}. ${error.message}` };
        });
      });
  
      // Execute all promises simultaneously using Promise.all
      Promise.all(promises)
        .then(results => {
          setResults(results);
          setError(null);
        })
        .catch(error => {

          console.error('Error fetching data:', error);
          setError(Error `fetching distance.${error.message}`);
        });
    };

  // const authenticateUser = async () => {
  //   const storedPhoneNumber = sessionStorage.getItem('phoneNumber');
  //   const storedPassword = sessionStorage.getItem('password');

  //   if (storedPhoneNumber && storedPassword) {
  //     // Automatically log in using stored credentials
  //     try {
  //       setOpenBackdrop(true);
  //       const response = await axios.post('https://mynode.trucksbooking.in/auth', {
  //         phonenumber: storedPhoneNumber,
  //         password: storedPassword,
  //       });
  //        if (response.status === 200) {
  //         // Successful login
  //         const userData = response.data.user;
  //         if (userData && userData.crn) {
  //           const userCRN = userData.crn;
  //           // Store user CRN in localStorage
  //           localStorage.setItem('userCRN', userCRN);
  //           console.log('Automatic Login successful');
  //           setMessage('Automatic Login Successful!');
  //           navigate('/OwnerInterface');    
  //         } 
  //         else {
  //           // Handle missing CRN in the response
  //           alert('Login Failed,Please Try Again')
  //           setError('CRN not found in user data');
  //         }
  //       }
  //     } 
  //     catch (error) {
  //       setOpenBackdrop(false);
  //       setError('Automatic login failed. Please log in manually.');
  //       console.error('Automatic Login error:', error);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   // Check for automatic login on component mount
  //   authenticateUser();
  // }, []);
  
  const handleLogin = async (e) => {
    e.preventDefault();

    // Validate input (you can add more validation)
    if (!phonenumber || !password) {
      setError('Please fill in all fields.');
      return;
    }
    

    try {
      setOpenBackdrop(true);

      // Send a request to your backend for authentication using axios
      const response = await axios.post('https://mynode.trucksbooking.in/auth', {
        phonenumber,
        password,
      });

      if (response.status === 200) {
        // Successful login
        const userData = response.data.user;
 const token = response.data.token;
 console.log('token',token)
        if (userData && userData.crn) {
          const userCRN = userData.crn;
          const feildCRN = userData.feildcrn;
          const owneremail= userData.owneremail;

          const name= userData.ownername;
          console.log(userCRN)
          console.log(feildCRN)
          const loggedStatus = userData.loggedstatus;
          localStorage.setItem('userToken', token);
          localStorage.setItem('onweremail',owneremail)
          // Store user CRN in localStorage
          localStorage.setItem('userCRN', userCRN);
          sessionStorage.setItem('userCRN', userCRN);
          sessionStorage.setItem('feildCRN', feildCRN);
          sessionStorage.setItem('phoneNumber', phonenumber);
          sessionStorage.setItem('password', password);
          sessionStorage.setItem('name', name);
          const loginTimestamp = new Date().getTime();
          localStorage.setItem('loginTimestamp', loginTimestamp);

          sessionStorage.setItem('displaySuccessMessage', 'true');
          sessionStorage.setItem('removesuccessmsg','true')

          // Redirect to the dashboard
          console.log('Login successful');
          setOpenBackdrop(false);
          // Check the logged status
          if (loggedStatus === 'pending') {
            // Navigate to update password page
            navigate('/MyForm', {
              state: { crn: userCRN, name: name },
            });
          } else if (loggedStatus === 'completed') {
            // Navigate to Owner Interface
            
        const data = {
            userCRN,
            feildCRN,
            owneremail,
            phonenumber,
            token,
            name,
        };

          // Navigate to the external URL with query parameters
          const dataString = JSON.stringify(data);
    const byteCode = CryptoJS.enc.Utf8.parse(dataString);
    const encodedData = CryptoJS.enc.Base64.stringify(byteCode);

    // Open localhost:3005 with encoded data in a new tab
    window.location.href=`https://${name}.trucksbooking.in/?data=${encodeURIComponent(encodedData)}`;
          } else {
            setError('Unknown logged status');
          }
        } else {
          setError('CRN not found in user data');
        }
      } else {
        // Failed login
        setOpenBackdrop(false);

        setError('Invalid phone number or password. Please try again.');
      }
    } catch (error) {
      setalertmsg(true);
      setTimeout(() => {
       console.log('msg')
       setalertmsg(false)
      }, 5000);
      setOpenBackdrop(false);

      setError('Invalid phone number or password. Please try again.');
      console.error('Login error:', error);
    }
};
const token =localStorage.getItem('usertoken')
const name = sessionStorage.getItem('name')
useEffect(()=>{
  if(token){
    window.location.href=`https://${name}.trucksbooking.in/`
  }
})
  const handleLogin1 = async (e) => {
    e.preventDefault();
  
    // Validate input (you can add more validation)
    if (!phonenumber || !password) {
      setError('Please fill in all fields.');
      return;
    }
  
    try {
      setOpenBackdrop(true);

      // Send a request to your backend for authentication
      const response = await axios.post('https://mynode.trucksbooking.in/Agentauth', {
        agentId: phonenumber,
        password,
      });
  
      console.log(response, 'login');
  
      if (response.status === 200) {
        // Agent login successful
  const token =  response.data.token;

  console.log('token',token);
  localStorage.setItem('agenttoken',token)
        // Fetch agent type
        const headers = {
          Authorization: `Bearer ${token}`,
        };
      
        const agentTypeResponse = await axios.get('https://mynode.trucksbooking.in/agentType', {
          params: {
            agentId: phonenumber,
          },
          headers: headers,
        });
  
        console.log(agentTypeResponse, 'resoponse');
  
        if (agentTypeResponse.status === 200) {
          const agentTypeData = await agentTypeResponse.data;
          console.log(agentTypeData, 'jijij');
  
          const agentStatus = agentTypeData[0].status;
  console.log('status',agentStatus)
          if (agentStatus === 'Inactive') {
            // Account is inactive
            setError('Your account has been deactivated by the owner.');
          } else if (agentStatus === 'active') {
            // Account is active
  
            const agentType = agentTypeData[0].agentType;
            const name = agentTypeData[0].name;
            const email = agentTypeData[0].email;

            const agentId = agentTypeData[0].agentId;
            const phonenumber = agentTypeData[0].phonenumber;
            const crn = agentTypeData[0].crn;
            sessionStorage.setItem('phoneNumber1', phonenumber);
            sessionStorage.setItem('password1', password);
            sessionStorage.setItem('agentCRN', crn);  
            sessionStorage.setItem('agentName', name);
            sessionStorage.setItem('email', email);

            sessionStorage.setItem('agentId', agentId);
            sessionStorage.setItem('agentType', agentType);
            const loggedStatus = agentTypeData[0].loggedstatus;
            sessionStorage.setItem('AgentSuccessMessage', 'true');
            sessionStorage.setItem('Agentremovesuccessmsg', 'true');
  
            if (loggedStatus === 'pending') {
              // Navigate to update password page
              navigate('/AgentForm', {
                state: { crn: crn, name: name },
              });
            } else if (loggedStatus === 'completed') {
              // Navigate to Agent Interface
              navigate('/AgentInterface', {
                state: { crn: crn, name: name },
              });
            } else {
              // Handle unknown logged status
              setError('Unknown logged status');
            }
          } else {
            // Handle unknown agent status
            setError('Unknown agent status');
          }
        } else {
          // Handle missing CRN in the response
          setError('CRN not found in user data');
        }
      } else {
        // Failed login
        const data = response.data;
        setError(data.message || 'Login failed.');
      }
    } catch (error) {
      setalertmsg(true);
      setTimeout(() => {
       console.log('msg')
       setalertmsg(false)
      }, 5000);
      setOpenBackdrop(false);
      setError('Invalid phone number or password.');
      setOpenBackdrop(false);

      console.error('Login error:', error);
    }
  };
  

  
  const [inputValue, setInputValue] = useState('');
  const [msg, setMsg] = useState('');
  const [msgerror, setMsgerror] = useState('');


  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = () => {
    if (inputValue === '1') {
      setMsg('Success!');
      setTimeout(() => {
        setMsg('');
      }, 3000);
    } else  {
      setMsgerror('Error!');
      setTimeout(() => {
        setMsgerror('');
      }, 3000);
    }
  };
  const togglePopup1 = () => {
    setPhoneNumber('')
    setPassword('')
    setError('')
    setPopupVisible1(!popupVisible1);
   
  };

  const togglePopup2 = () => {
    setPhoneNumber('')
    setPassword('')
    setError('')
    setPopupVisible2(!popupVisible2);
   
  };
  const togglePopup3 = () => {
    setPopupVisible3(!popupVisible3);
  };

  const handleLogout = () => {
    // Clear local storage and navigate to the login page
    localStorage.removeItem('userCRN');
    sessionStorage.removeItem('phoneNumber');
    sessionStorage.removeItem('password');
    navigate('/login'); // Navigate to the login page
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
const [showlogoutMessage, setShowlogoutMessage] = useState(false);
useEffect(() => {
  // Check if the success message should be displayed
  const shouldDisplayMessage = sessionStorage.getItem('removesuccessmsg');
  if (shouldDisplayMessage) {
    // Display the success message
    setShowlogoutMessage(true);
    // Remove the flag from sessionStorage
    sessionStorage.removeItem('removesuccessmsg');
    // Automatically hide the message after 5 seconds
    setTimeout(() => {
      setShowlogoutMessage(false);
    }, 5000);
  }
}, []);

const [AgentshowlogoutMessage, AgentsetShowlogoutMessage] = useState(false);

useEffect(() => {
  // Check if the success message should be displayed
  const shouldDisplayMessage = sessionStorage.getItem('Agentremovesuccessmsg');
  
  if (shouldDisplayMessage) {
    // Display the success message
    AgentsetShowlogoutMessage(true);
    // Remove the flag from sessionStorage
    sessionStorage.removeItem('Agentremovesuccessmsg');
    // Automatically hide the message after 5 seconds
    setTimeout(() => {
      AgentsetShowlogoutMessage(false);
    }, 5000);
  }
}, []);
const [chatButtonLoaded, setChatButtonLoaded] = useState(true);

  useEffect(() => {
    // Load the IntelliTicks chat button script dynamically
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdn-v1.intelliticks.com/prod/common/pbBMqAaShPqZtvHYF_c/inject-v2.min.js';
    document.head.insertBefore(script, document.head.firstChild);

    // Set flag once script is loaded
    script.onload = () => setChatButtonLoaded(true);

    return () => {
      // Clean up script on component unmount (optional)
      script.remove();
    };
  }, []);

  return (
    <div>
 
    <div className='bg' style={{ backgroundImage: `url(${imgage})` }}>
<nav class="navbar navbar-expand-lg p-3" style={{position:'sticky',backgroundColor:' rgba(126, 175, 179, 0.5)'}}>
<img src={img} alt="logo"  style={{maxWidth:'40px',padding:'0px',margin:'0px'}}className="rounded-circle"/>
    <button class="navbar-toggler bg-light bg-primary" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-bs-controls="navbarNav" aria-bs-expanded="false" aria-bs-label="Toggle navigation">
        <MenuOutlinedIcon fontSize='large' />
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
           
        </ul>
        <ul class="navbar-nav ml-auto" style={{color:'black'}}>
      
            <li class="nav-itemmain ">
                <button class="nav-linkmain"  href="" style={{backgroundColor:'transparent',color:'black'}} onClick={togglePopup3}>Distance Calculator</button>
            </li>  
          <li className='nav-itemmain'>
          <button className=' fw-bold' style={{backgroundColor:'transparent',color:'black'}} onClick={togglePopup2}>Agent Login</button>
          </li>
          <li class="nav-itemmain">
            <button className=' fw-bold' style={{backgroundColor:'transparent',color:'black'}} onClick={togglePopup1}>Truck Owner Login</button>
            </li>
    </ul>
    </div>
</nav>
{/* <img src={imgage} style={{width:'100%',height:"100%"}}></img> */}
  
{showlogoutMessage && (
        <div className="success-message mt-4 p-3" >
              <Alert severity="error" variant='filled'>
              <strong> Logout Successful !</strong>

             </Alert>
        </div>
      )}
      {AgentshowlogoutMessage && (
        <div className="success-message mt-4 p-3" >
              <Alert severity="error" variant='filled'>
              <strong> Logout Successful !</strong>

             </Alert>
        </div>
      )}

    
{alertmsg && (
  <div className="success-message mt-4 p-3" style={{ position: 'fixed', top: '10%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999 }}>
    <Alert severity="error" variant='filled'>
      <strong>Invalid phone number or password!</strong>
    </Alert>
  </div>
)}


{popupVisible3 && (
   <form className='mt-5 container pb-3' style={{height:'fit-content'}}  id='distance-calculator'>
     <div style={{display:'flex',justifyContent:'end'}} ><span className="close-buttons mb-3"  onClick={togglePopup3}>&times;</span></div>
     <Typography variant='h4' bgcolor={'orange'} className='mb-3' sx={{textAlign:'center',borderRadius:'5px' }}>
            Distance Calculator
          </Typography>
  
   <Grid container spacing={2} justify="center">
      <Grid item xs={12}>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
           label="From Pincode"
           variant="outlined"
           name="toPincode"
           id="fromPincode" 
           value={fromPincode} 
           onInput={(e) => {
            // Allow only digits
            e.target.value = e.target.value.replace(/\D/g, ''); 
            
            // Ensure maximum length of 6 digits
            if (e.target.value.length > 6) {
              e.target.value = e.target.value.slice(0, 6);
            }
    
            setFromPincode(e.target.value);
            
          }}
          error={error && error.length > 0}
          helperText={error}
           placeholder="Enter From Pincode"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
             label="To Pincode"
             variant="outlined"
             name="toLocation"
             id="toPincode"
              value={toPincode}
              onInput={(e) => {
                // Allow only digits
                e.target.value = e.target.value.replace(/\D/g, ''); 
                
                // Ensure maximum length of 6 digits
                if (e.target.value.length > 6) {
                  e.target.value = e.target.value.slice(0, 6);
                }
        
                setToPincode(e.target.value);
              }}
              error={error && error.length > 0}
              helperText={error}
             placeholder="Enter To Pincode"
          fullWidth
        />
      </Grid>
     
      <Grid item xs={12}>
        <div className=' d-flex justify-content-center flex-row' > 
      <Button onClick={calculateDistance} variant='contained' className='w-50 ' >Submit  </Button>
      </div>
      </Grid>
    </Grid>
    {results.error && <div className="alert alert-danger mt-3" role="alert">{results.error}</div>}
    {results.length > 0 && (
        <div className="table-responsive mt-3">
          <table className="table table-bordered table-primary table-hover">
            <thead>
              <tr>
                <th>Mode</th>
                <th>Distance (km)</th>
                <th>Duration (minutes)</th>
              </tr>
            </thead>
            <tbody>
              {results.map((result, index) => (
                <tr key={result.mode}>
                  <td style={{ fontWeight: 'bold', backgroundColor: index % 2 === 0 ? '#f0f0f0' : '#ffffff' }}>{result.mode}</td>
                  <td style={{ backgroundColor: index % 2 === 0 ? '#f0f0f0' : '#ffffff' }}>{result.distance !== null ? result.distance.toFixed(2) : 'N/A'}</td>
                  <td style={{ backgroundColor: index % 2 === 0 ? '#f0f0f0' : '#ffffff' }}>{result.duration !== null ? result.duration.toFixed(2) : 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

</form>


  )}

  <div class="collapse "   id="collapseExample" >
  
  <div class="card card-body container" style={{zIndex:'1',backgroundColor:"#f2f4fb"}}>
    
     
      <Typography  variant="p" component="p"  className='text-center'>
           <table className='table border table-primary'>
            <tr>
              <th>From location - Pincode</th>
              <th>To Location - Pincode</th>
              <th> Distance KMs</th>
            </tr>
           </table>
           </Typography>
           <Typography className='mt-4 text-center text-primary' variant='h6'>
             Country-State-District-mandel
           </Typography>
           <Typography id="modal-modal-description" sx={{ mt: 2 }}>
             <div>
               <table class="table table-primary table-sm table-hover "  >
                 <tr>
                   <th>S.NO</th>
                   <th>Sub Location Name</th>
                   <th>Pincode</th>
                   <th>Distance In KMs</th>
                 </tr>
                 <tbody> 
                 <tr >
                   <td>1</td>
                   <td>abcdefdfdf</td>
                   <td>343535</td>
                   <td>5757km</td>
                 </tr>
                 <tr >
                   <td>2</td>
                   <td>abcdefdfdf</td>
                   <td>343535</td>
                   <td>5757km</td>
                 </tr>
                 <tr >
                   <td>3</td>
                   <td>abcdefdfdf</td>
                   <td>343535</td>
                   <td>5757km</td>
                 </tr>
                 <tr >
                   <td>4</td>
                   <td>abcdefdfdf</td>
                   <td>343535</td>
                   <td>5757km</td>
                 </tr>
                 </tbody>
               </table>
               <center> 
               <Button variant="contained" color='error' className='mt-4 ps-5 pe-5 mb-4 warning' data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" >Clear
              </Button>
              </center>
             </div>
           </Typography>
  </div>
  
</div>
<Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={null} // Set onClick to null to disable closing on click
      >
        <div>
        <CircularProgress color="inherit" className='ms-2' />
        <br/>
        <Typography variant="h6" color="inherit" component="div" style={{marginTop: '10px'}}>
          Loading...
        </Typography>
        </div>

      </Backdrop>   
<div className='text-ani-div'>
      <Typography class="typing-fade-in-text text-light" variant='h1'>Welcome to <span className='text-warning'>GRCTOB</span></Typography>

<Typography variant='h5' className='fade-in-text text-light'>
Your journey, our commitment <span className='text-light'>-</span> <span className='' style={{color:'black'}}> welcome to seamless trucking</span> </Typography> 
   </div>

   <div className='photo'>  

    <div className="App">
      
  
     <center>
  <Typography style={{ paddingTop: '20px' }}>
    {msg && (
      <span style={{  display: 'inline-block',fontWeight:'bold',fontSize:'30px'}}>
              <Alert severity="success" className='text-bold' style={{fontWeight:'bold'}}><b> This is a {msg} alert — check it out!</b></Alert>

      </span>
    )}
     {msgerror && (
      <span style={{ 
        display: 'inline-block',fontWeight:'bold',fontSize:'30px'
      }}>
        <Alert severity="error" className='' style={{fontWeight:'bold'}}>This is a {msgerror} alert — check it out!</Alert>
      </span>
    )}
  </Typography>
</center>
 {/* <div >
  <label>
    Enter value:
    <input type="text" value={inputValue} onChange={handleInputChange} />
  </label>
  <button onClick={handleSubmit}>Submit</button>
</div>   */}


      <div className="buttons-container1">
      </div>
      </div>
 
 

      {popupVisible1 && (
        
        <div className="popup-container1" id="popupSignIn">
 <div className="popup1">
  <div>
      <span className="close-button" onClick={togglePopup1}>&times;</span>
      <p><span><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
  <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
</svg></span></p>
      <h2 style={{fontFamily:'Segoe UI',textShadow:'1px 2px 2px gray',}}>Truck Owner Login</h2>
 </div>
 <br/>
 
       {error && <div className="error">{error}</div>}
    {message && <div className="message">{message}</div>}

      {/* <input type="text"  id='lfelid' placeholder="Enter Phonenumber" inputMode='tel'  value={phonenumber} onChange={(e) => setPhoneNumber(e.target.value)} required></input><br></br>
       <input type="password" id='lfelid'  placeholder="Enter Password" value={password} 
          onChange={(e) => setPassword(e.target.value)} required></input> */}
  
      <TextField
        id="lfeild"
        label="Username"
        placeholder="Enter Phone Number"
        inputMode='tel'
        type='text'
        value={phonenumber}
        onInput={(e) => {
          // Allow only digits
          e.target.value = e.target.value.replace(/\D/g, ''); 
          
          // Ensure maximum length of 6 digits
          if (e.target.value.length > 10) {
            e.target.value = e.target.value.slice(0, 10);
          }
  
          setPhoneNumber(e.target.value);
          
        }}
        required
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
              <AccountCircle color="primary" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

       <TextField
        id="lfeild"
        label="Password"
        type="password"
        className='mt-3'
        placeholder="Enter Password" 
        value={password} 
        onChange={(e) => setPassword(e.target.value)}
         required
         variant="outlined"
         InputProps={{
           endAdornment: (
             <InputAdornment position="end">
               <IconButton onClick={() => setShowPassword(!showPassword)}>
                 {showPassword ? <LockIcon /> : <LockIcon color="error" />}
               </IconButton>
             </InputAdornment>
           ),
         }}
      />
      <Button variant="contained" type='submit' className='mt-4 ps-5 pe-5 mb-2' onClick={handleLogin}>Submit</Button>
       <br/>
     <Link to='/ForgotPasswordOwner' style={{marginTop:'7px'}}><a href="#" className='' style={{fontFamily:'Segoe UI'}}>forgot password ?</a></Link>
     <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        onClick={null} // Set onClick to null to disable closing on click
      >
        <div>
        <CircularProgress color="inherit" />
        <br/>
        <Typography variant="h6" color="inherit" component="div" style={{marginTop: '10px'}}>
          Loading...
        </Typography>
        </div>

      </Backdrop>   
    </div>     
     </div>
      )}

      {popupVisible2 && (
        <div className="popup-container2" id="popupSignIn1">
<div className="popup1">
  <div className='head'>
      <span className="close-button" onClick={togglePopup2}>&times;</span>
      <p><span><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"   fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
  <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
</svg></span></p>
      <h2 style={{fontFamily:'Segoe UI',textShadow:'1px 2px 2px gray',}}>Agent Login</h2>
  </div>
  <br/>
      {error && <div className="error ms-2">{error}</div>}
    {message && <div className="message ms-2">{message}</div>}
 
       {/* <input type="text" placeholder="Agent ID" inputMode='tel' id='lfelid' value={phonenumber} onChange={(e) =>  setPhoneNumber(e.target.value)} required></input><br></br>
       <input id='lfelid' type="password" placeholder="Enter Password"value={password}
          onChange={(e) => setPassword(e.target.value)}  required></input> */}
                <TextField
        id="lfeild"
        label="Agent ID"
        placeholder="Enter Agent ID"
        inputMode='tel'
        type='text'
        value={phonenumber}
        onInput={(e) => {
          // Allow only digits
          e.target.value = e.target.value.replace(/\D/g, ''); 
          
          // Ensure maximum length of 6 digits
          if (e.target.value.length > 6) {
            e.target.value = e.target.value.slice(0, 6);
          }
  
          setPhoneNumber(e.target.value);
          
        }}
        required
        className='pb-2'
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
                   <IconButton>
              <AccountCircle color='primary' />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

       <TextField
        id="lfeild"
        label="Password"
        type="password"
        className='mt-3'
        placeholder="Enter Password" 
        value={password} 
        onChange={(e) => setPassword(e.target.value)}
         required
         variant="outlined"
         InputProps={{
           endAdornment: (
             <InputAdornment position="end">
               <IconButton onClick={() => setShowPassword(!showPassword)}>
                 {showPassword ? <LockIcon /> : <LockIcon color="error" />}
               </IconButton>
             </InputAdornment>
           ),
         }}
      />
    
      <br/>
      <Button variant="contained" type='submit' className='mt-4 ps-5 pe-5 mb-2' onClick={handleLogin1}>Submit</Button>
         <br/>
         <Link to='/ForgotPasswordAgent' style={{marginTop:'7px'}}><a href="#" className='' style={{fontFamily:'Segoe UI'}}>forgot password ?</a></Link>
     <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        onClick={null} // Set onClick to null to disable closing on click
      >
          <div>
        <CircularProgress color="inherit" />
        <br/>
        <Typography variant="h6" color="inherit" component="div" style={{marginTop: '10px'}}>
          Loading...
        </Typography>
        </div>

      </Backdrop>  
    </div>      
    </div>
      )}
    </div>
    </div>

{/* <div className='cards-bg'> 
    <div class="card-deck">
    <div class="custom-card">
        <img src={img6} alt="Card 1"/>
        <div class="custom-card-content">
            <h3>Card 1</h3>
            <p>This is the content of Card 1.</p>
        </div>
    </div>

    <div class="custom-card">
        <img src={img3} alt="Card 2"/>
        <div class="custom-card-content">
            <h3>Card 2</h3>
            <p>This is the content of Card 2.</p>
        </div>
    </div>

    <div class="custom-card">
        <img src={img4} alt="Card 2"/>
        <div class="custom-card-content">
            <h3>Card 2</h3>
            <p>This is the content of Card 2.</p>
        </div>
    </div>

    <div class="custom-card">
        <img src={img5} alt="Card 2"/>
        <div class="custom-card-content">
            <h3>Card 2</h3>
            <p>This is the content of Card 2.</p>
        </div>
    </div>
  
</div>
<div class="card-deck">
    <div class="custom-card">
        <img src={img6} alt="Card 1"/>
        <div class="custom-card-content">
            <h3>Card 1</h3>
            <p>This is the content of Card 1.</p>
        </div>
    </div>

    <div class="custom-card">
        <img src={img7} alt="Card 2"/>
        <div class="custom-card-content">
            <h3>Card 2</h3>
            <p>This is the content of Card 2.</p>
        </div>
    </div>

    <div class="custom-card">
        <img src={img8} alt="Card 2"/>
        <div class="custom-card-content">
            <h3>Card 2</h3>
            <p>This is the content of Card 2.</p>
        </div>
    </div>

    <div class="custom-card">
        <img src={img2} alt="Card 2"/>
        <div class="custom-card-content">
            <h3>Card 2</h3>
            <p>This is the content of Card 2.</p>
        </div>
    </div>
  
</div>
</div>
   */}
<div className='aboutusbg pt-1'>
     <Typography variant="h5"  className='text-center mt-3 mb-4 pt-1'>
       <span className='text-danger'><WidgetsOutlinedIcon size='large' className='me-2'/></span> ADVANCE FEATURES
      </Typography>
      <Typography variant="h3"  className='text-center mt-3 mb-5'>
      Benefits Of  <span className='text-danger'>GRCTOB</span>
      </Typography>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}> 
          <motion.div
            initial="hidden"
            animate="visible"
            variants={cardVariants}
            transition={{ duration: 1}}
            className='pb-5'
          >
        <Grid container spacing={4}  className='card-deck '>
      {/* Card 1 */}
      <Grid item xs={12} sm={6} md={4} >
        <Card sx={{ maxWidth: 345 }} >
          <CardActionArea>
          <div className='d-flex justify-content-center flex-row'>
          <Button style={{borderRadius:'100px'}}  className='mt-3 text-danger'><MonetizationOnRoundedIcon style={{fontSize:'50px', }}/></Button>
          </div>
        
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
              Affordable Cost
              </Typography>
              <Typography variant="body2" color="text.secondary">
              Proin eget tortor risus. Donec rutrum congue leo eget malesuada. Curabitur
              </Typography>
            </CardContent>
          </CardActionArea>

        </Card>
      </Grid>

      {/* Card 2 */}
      <Grid item xs={12} sm={6} md={4}>
        <Card sx={{ maxWidth: 345 }} >
           
          <CardActionArea>
          <div className='d-flex justify-content-center flex-row'>
          <Button style={{borderRadius:'50px'}}  className='mt-3 text-danger'><LocalShippingRoundedIcon style={{fontSize:'50px', }}/></Button>
          </div>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
              Shot Time and Safe Delivery
              </Typography>
              <Typography variant="body2" color="text.secondary">
              Proactively administrate low-risk high yield total linkage manufactured.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>

      {/* Card 3 */}
      <Grid item xs={12} sm={6} md={4}>
        <Card sx={{ maxWidth: 345 }} >
          <CardActionArea>
          <div className='d-flex justify-content-center flex-row'>
          <Button style={{borderRadius:'50px'}}  className='mt-3 text-danger'><SupportAgentRoundedIcon style={{fontSize:'50px', }}/></Button>
          </div>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
              Customer Support
              </Typography>
              <Typography variant="body2" color="text.secondary">
              Taking of a administrate low-risk high yield products Wen for some.
              </Typography>
            </CardContent>
          </CardActionArea>
        
        </Card>
      </Grid>
    </Grid>
          </motion.div>
          </div>   
    </div>
    

<div className='about-us-bg'>
  <Typography variant='h2' className='text-center mb-4 mt-4'>About <span className='text-danger'>Us</span></Typography>
  <div className='container '>
  <div className='row p-5 border'>
    
    <div className='col-12 col-md-5 about-us-img' >
    
    </div>
    
    <div className='col-12 ps-5 pt-2 col-md-7'>
      <Typography variant='h4'>Your content automatically looks its best across devices</Typography>
      <Typography variant='p'>Carefully created themes help make your content stand out. Sites intelligently optimize your work so that it looks great on desktop, tablet, and mobile.</Typography>
       <div className='mt-3 ms-2 '>
       <Button variant='contained'>View More </Button>
       </div>
    </div>
  </div>
</div>
</div> 

<div className='ask-queries-container '>
  
<Grid container spacing={3} id='questions-grid-background' className='container'>
      {/* First Column: Form */}
      <Grid item xs={12} sm={6} md={5} lg={5} >
        <Card elevation={3} style={{ padding: 20 }} id='questions-form'>
          <CardContent>
            <Typography variant="h4" style={{fontWeight:'bold'}}  gutterBottom>
              Have Any Questions
            </Typography>
            <form>
              <div className='d-flex' > 
              <TextField
                label="Name"
                type='text'
                variant="standard"
                margin="normal"
                fullWidth
              />
              <TextField
                label="Phone Number"
                type='number'
                variant="standard"
                margin="normal"
                fullWidth
                className='ms-5'
              />

              </div>
              <TextField
                label="Email Id"
                type='email'
                variant="standard"
                margin="normal"
                fullWidth
              />
              <TextField
                label="Your Query"
                variant="outlined"
                margin="normal"
                fullWidth
                multiline
                rows={4}
              />
              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
            </form>
          </CardContent>
        </Card>
      </Grid>

      {/* Second Column: Accordion */}
      <Grid item xs={12} sm={6} md={7} lg={7}>
        <Card elevation={3} style={{ padding: 20 }}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
             <b>Frequently Ask <span className='asked-que text-danger'>Questions</span></b>
            </Typography>
            <div>
      <Accordion
        expanded={expandedAccordion === 'accordion1'}
        onChange={handleChange('accordion1')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><b>01. Question?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Details of Accordion Item 1.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <div style={{ margin: '16px' }} />

      <Accordion
        expanded={expandedAccordion === 'accordion2'}
        onChange={handleChange('accordion2')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><b>02. Question?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Details of Accordion Item 2.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <div style={{ margin: '16px' }} />

      <Accordion
        expanded={expandedAccordion === 'accordion3'}
        onChange={handleChange('accordion3')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><b>03. Question?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Details of Accordion Item 3.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <div style={{ margin: '16px' }} />

      <Accordion
        expanded={expandedAccordion === 'accordion4'}
        onChange={handleChange('accordion4')}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography><b>04. Question?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Details of Accordion Item 4.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
          </CardContent>
        </Card>
      </Grid>
    </Grid>

</div>

 <div className="container mt-5">
      <div className="chat-iconmain fa-4x" id="chatIcon" style={{ color: 'yellow' }}>
        {/* Chat button will be dynamically rendered here */}
        {chatButtonLoaded && (
          <Box sx={{ height: 320, transform: 'translateZ(0px)', flexGrow: 1 }} style={{ backgroundColor: 'yellow' }}>
            <SpeedDial
              ariaLabel="SpeedDial openIcon example"
              sx={{ position: 'absolute', bottom: 16, right: 16 }}
              icon={<SpeedDialIcon openIcon={<EditIcon />} />}
            >
              <SpeedDialAction icon={<SmsIcon />} tooltipTitle="Frequently Asked Questions" onClick={navigatetodashboard} />
              <SpeedDialAction icon={<PhoneForwardedIcon />} tooltipTitle="Phone" onClick={() => (window.location.href = 'tel:123456789')} />
              <SpeedDialAction icon={<WhatsAppIcon />} tooltipTitle="WhatsApp" onClick={() => (window.location.href = 'https://wa.me/123456789')} />
            </SpeedDial>
          </Box>
        )}
      </div>
    </div>
<Footer/>

    </div>

  );
}

export default  Dashboard;
